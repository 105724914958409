<template>
    <v-dialog
        v-model="dialog"
        width="600"
        persistent            
    >
        <v-card
            class="pa-4"
            height="90vh"
        >
            <v-card-title>
                Edit / View Term Registration
            </v-card-title>
            <v-data-table
                :headers="headersTermRegistration"
                :items="termRegistrations"
                :search="search"
                :loading="loading"
                height="50vh"
                fixed-header
            >
                <template v-slot:top>                        
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search Student"
                        single-line
                        hide-details
                        class="mb-4"                            
                    ></v-text-field>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                
            </v-data-table>
            <v-snackbar
                v-model="snack"
                :timeout="3000"
                color="primary"
            >
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn
                        v-bind="attrs"
                        text
                        @click="snack = false"
                    >
                        Close
                    </v-btn>
                </template>
            </v-snackbar>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    outlined
                    @click="closeDialog"
                >
                    Close
                </v-btn>
            </v-card-actions>
            <v-overlay
                absolute
                :value="overlay"
                color="grey"
                opacity="0.6"
            >
                <v-progress-circular
                    indeterminate 
                    size="64"                        
                    color="primary"
                ></v-progress-circular>
            </v-overlay>                 
        </v-card>
        <v-dialog></v-dialog>
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    props: {
        dialogOpen: Boolean,
    },

    data: function () {
        return {
            dialog: this.dialogOpen,
            termRegistrations: [],
            search: '',
            headersTermRegistration:[
                {text: 'Student ID', value: 'student_id'},
                {text: 'First Name', value: 'first_name'},
                {text: 'Last Name', value: 'last_name'},
                {text: 'Class', value: 'form_class_id', align: 'center'},
                {text: 'Actions', value: 'actions', align: 'center'},
            ],
            loading: false,
            overlay: false,
            snack: false,
            snackText: '',
        }
    },

    methods: {
        async initializeTermRegistration(){
            this.loading = true;
            try {
                let response = await this.getTermRegistrations();
                this.termRegistrations = response.data;
                this.loading = false;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        ...mapActions({
            getTermRegistrations: 'settings/getTermRegistrations',
            deleteTermRegistration: 'settings/deleteTermRegistration'
        }),

        ...mapMutations({
            setDeletedStudent: 'settings/setDeletedStudent',
        }),

        async deleteItem(item){
            this.overlay = true;
            this.setDeletedStudent(item);
            try {
                let response = await this.deleteTermRegistration();
                console.log(response.status);
                response = await this.getTermRegistrations();
                this.termRegistrations = response.data;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlay = false;
            setTimeout(() => {
                this.snack = true;
                this.snackText = `${item.student_id} Deleted`;
            }, 500);
        },

        closeDialog(){
            this.dialog = false;
            this.setSelectedItem({item: -1});
        },
        
        save () {
            this.snack = true;
            this.snackText = 'Data Saved'
        },
        open () {
            this.snack = true;
            this.snackText = 'Dialog opened'
        }
    }
}
</script>